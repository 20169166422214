import { useNavigate } from "react-router-dom";

export default function Attendance() {

  const navigate = useNavigate();

  const handleContactClick = (async) => {
    navigate("/contact");
  };

  return (
    <div>
      <section className="first-section">
        <div className="box-main grid two">
          <div>
            <p className="bold really-large-font text-margin-more-top left-align more-left-margin bottom-margin">
              Krankmelden & <br></br> Fehlstunden <br></br> verwalten
            </p>
            <a onClick={() => handleContactClick()} className="round-button black more-left-margin">Beginnen</a>
          </div>

          <img
            className="feature-image even-more-top-margin"
            src={require("./assets/heart-dynamic-color.png")}
            alt="heart"
          ></img>
        </div>
      </section>
      <section className="more-top-margin">
        <div className="ninth-width black-background border-radius left-margin fixed-seventh-height relative">
          <p className="white medium semi-bold center text-margin-top">
            Als Schüler & Lehrer mit wenigen Klicks krankmelden.
          </p>
          <br></br>
          <img
            className="fourfth-width third-left-margin absolute"
            src={require("./assets/Group 726.png")}
            alt="mockup"
          ></img>
          <img
            className="third-width centric relative more-top-margin"
            src={require("./assets/Group 723.png")}
            alt="notification"
          ></img>
        </div>
      </section>
      <section className="more-top-margin">
        <div className="grid two left-margin ninth-width semi-height">
          <div className="black-background ninth-width border-radius clay-height">
            <p className="white bold really-large left-margin text-margin-more-top">
              FÜR SCHULEN
            </p>
            <p className="white left-margin limited-width text-small-margin-top">
              Über das Jahr sammeln sich an Ihrer Schule viele Fehlstunden an.
              Diese müssen von Lehrern gepflegt und zum Ende ausgewertet werden.
              Durch die automatische Fehlstunden Verwaltung müssen bei der
              Zeugnis Erstellung zukünftig nur noch die Fehlstunden abgelesen
              werden.
            </p>
            <img
              className="third-width left-margin top-margin block small-bottom-margin"
              src={require("./assets/locker-dynamic-color.png")}
              alt="clock"
            ></img>
            <a onClick={() => handleContactClick()} className="round-button left-margin">
              Automatische Zeugniserstellung
            </a>
          </div>
          <div className="black-background ninth-width border-radius more-left-margin">
            <p className="white bold really-large left-margin text-margin-more-top">
              FÜR LEHRER
            </p>
            <p className="white left-margin limited-width text-small-margin-top">
              Abwesenheiten oder Fehlstunden verwalten, zählen & auswerten
              gehört mit Signs nicht mehr zur Aufgabe der Lehrer. Ein Großteil
              der Abläufe und Dokumentation ist bereits automatisiert und wir
              arbeiten weiter daran das Leben Ihrer Lehrer einfacher zu machen.
            </p>
            <img
              className="third-width left-margin top-margin block small-bottom-margin"
              src={require("./assets/folder-dynamic-color.png")}
              alt="clock"
            ></img>
            <a onClick={() => handleContactClick()} className="round-button left-margin">Kontakt zu Signs</a>
          </div>
        </div>
      </section>
      <section className="more-top-margin">
        <div className="ninth-width green-background border-radius left-margin fixed-seventh-height grid two">
          <div>
            <p className="really-large-font bold more-left-margin text-margin-third">
              BESSERE NOTEN
            </p>
            <p className="reduced-width more-left-margin">
              Unentschuldigte Fehlstunden wirken sich negativ auf die Noten der
              Schüler aus, oft vergessen sie allerdings nur sich vom Lehrer
              entschuldigen zu lassen. Signs stellt automatisch
              Entschuldigungsanfragen an Lehrer und erinnert diese daran,
              sollten sie es vergessen. Auf nur einen Blick sehen Schüler &
              Eltern wie oft sie gefehlt haben.
            </p>
          </div>
          <img
            className="half-width even-more-left-margin even-more-top-margin"
            src={require("./assets/phone-only-dynamic-premium.png")}
            alt="phone"
          ></img>
        </div>
      </section>
      <section className="even-more-top-margin">
        <div className="grid two">
          <img
            className="half-width even-more-left-margin"
            src={require("./assets/Group 727.png")}
            alt="attendance"
          ></img>

          <div>
            <p className="large-font">Abwesenheiten</p>
            <p className="limited-width">
              Mit Signs kontrollieren die Lehrer mit wenigen Klicks die
              Anwesenheit, die Dokumentation übernimmt das System von Signs und
              pflegt die Fehlstunden mit ein.
            </p>
          </div>
        </div>
      </section>
      <section className="even-more-top-margin bottom-margin centric">
        <p className="large-font bottom-margin">
          Na gut ich bin bereit. <br></br>Wie lege ich los?
        </p>
        <a onClick={() => handleContactClick()} className="round-button black">Starten</a>
      </section>
    </div>
  );
}
