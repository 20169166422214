import { useNavigate } from "react-router-dom";

export default function Signs() {
  const navigate = useNavigate();

  const handleContactClick = (async) => {
    navigate("/contact");
  };

  return (
    <div>
      <section className="first-section">
        <div className="box-main grid two">
          <img
            className="half-image even-more-top-margin even-more-left-margin"
            src={require("./assets/signs-image.png")}
            alt="signs"
          ></img>

          <div>
            <p className="gray semi-bold medium left-align text-margin-more-top">
              signs
            </p>
            <p className="medium bold large-font left-align">
              KOMMUNIKATION WAR NOCH NIE SO EINFACH
            </p>
            <div className="reduced-width space-between small-top-margin">
              <div className="round-button small-border transparent-background">
                <p>chatten</p>
              </div>
              <div className="round-button small-border transparent-background">
                <p>fragen</p>
              </div>
              <div className="round-button small-border transparent-background">
                <p>Einwilligungen</p>
              </div>
              <div className="round-button small-border transparent-background">
                <p>Fragebogen schicken</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="even-more-top-margin">
        <div className="grid two">
          <div>
            <p className="large-font bold even-more-left-margin text-margin-more-top">
              SOOOO SCHNELL
            </p>
            <p className="even-more-left-margin bottom-margin limited-width">
              Verbinden Sie Ihre Lehrer, Schüler & Eltern - Signs ermöglicht
              einfache Kommunikation, Verwaltung & Einholen von Einwilligungen,
              sensiblen Informationen & mehr.
            </p>
            <a onClick={() => handleContactClick()} className="round-button black even-more-left-margin">
              Kostenlose Demo buchen
            </a>
          </div>
          <img
            className="ninth-width left-margin"
            src={require("./assets/Group 731.png")}
            alt="signs"
          ></img>
        </div>
      </section>
      <section className="even-more-top-margin">
        <div className="ninth-width light-background clay-height grid two left-margin border-radius half-height">
          <img
            className="half-width even-more-left-margin even-more-top-margin"
            src={require("./assets/Group 732.png")}
            alt="signs-messages"
          ></img>
          <div>
            <p className="large-font bold text-margin-third">
              MEHR ALS CHATTEN.
            </p>
            <p className="limited-width bottom-margin">
              Signs ist mehr als Kommunikation - erstellen Sie Fragebögen, holen
              Sie Einwilligungen ein oder informieren Sie einzelne Eltern und
              Schüler oder ganze Klassen. Sie brauchen etwas von Kollegen?
              Schreiben Sie diese direkt an oder fragen Sie direkt in einer
              Gruppe mehrere Lehrer.
            </p>
          </div>
        </div>
      </section>
      <section className="more-top-margin">
        <div className="grid two left-margin ninth-width semi-height">
          <div className="black-background ninth-width border-radius">
            <p className="white bold really-large left-margin text-margin-more-top">
              SIE HABEN EINEN <br></br> ELTERNBRIEF ERHALTEN
            </p>
            <p className="white left-margin limited-width text-small-margin-top">
              Ein ganzer Stapel Briefe? Der Zettel muss morgen unterschrieben
              werden? Mit Signs geben Sie Ihre Einwilligung mit nur einem Klick.
            </p>
            <img
              className="third-width centric top-margin block bottom-margin"
              src={require("./assets/mail-dynamic-clay.png")}
              alt="clock"
            ></img>
            <a onClick={() => handleContactClick()} className="round-button left-margin">Signs empfehlen</a>
          </div>
          <div className="black-background ninth-width clay-height border-radius more-left-margin">
            <p className="white bold really-large left-margin text-margin-more-top">
              SIGNS VERBINDET.
            </p>
            <p className="white left-margin limited-width text-small-margin-top">
              Mit Signs verbinden wir die Gemeinschaft an Ihrer Schule. Öffnen
              Sie einfach Chats mit Lehrern, Schülern, Eltern oder ganzen
              Klassen.
            </p>
            <img
              className="fourfth-width centric more-top-margin block "
              src={require("./assets/chat-bubble-dynamic-clay.png")}
              alt="calendar"
            ></img>
            <a onClick={() => handleContactClick()} className="round-button left-margin">Start</a>
          </div>
        </div>
      </section>
      <section className="even-more-top-margin">
        <div className="dark-blue-background extended-width fixed-half-height">
          <p className="left-margin text-margin-top">
            SCHULLEITER VON GYMNASIUM IN BERLIN
          </p>
          <p className="left-margin large-font">
            “Können im Vergleich zu Sdui deutlich mehr Zeit sparen.”
          </p>
        </div>
      </section>
      <section className="even-more-top-margin">
        <div className="ninth-width black-background clay-height grid two left-margin border-radius half-height">
          <div>
            <p className="white large-font bold text-margin-third even-more-left-margin">
              FÜR SCHULEN
            </p>
            <p className="white limited-width bottom-margin even-more-left-margin">
              Um Ihre Schule rechtlich abzusichern, müssen regelmäßig
              Einwilligungen oder sensible Informationen von Eltern, Schülern
              und auch Lehrern eingeholt werden. Egal ob Impfnachweise,
              Unterschriften oder Informationen für eine Klassenfahrt, mit Signs
              können Sie mit wenigen Klicks sicher und privat ganze Klassen oder
              Jahrgänge danach fragen.
            </p>
          </div>
          <img
            className="half-width even-more-left-margin even-more-top-margin"
            src={require("./assets/copy-dynamic-clay.png")}
            alt="signs-messages"
          ></img>
        </div>
      </section>
      <section className="even-more-top-margin">
        <p className="center large-font bold bottom-margin">
          WERDEN SIE EINE VON HUNDERTEN SCHULEN DIE SIGNS NUTZEN
        </p>
        <a onClick={() => handleContactClick()} className="round-button third-width black centric top-margin bottom-margin">
          Mit Signs durchstarten
        </a>
      </section>
    </div>
  );
}
