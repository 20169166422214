export default function About() {

  const handleContact = (event) => {

  };

  return (
    <div>
      <section>
        <img
          className="fourfth-width centric more-top-margin"
          src={require("./assets/Group 740.png")}
          alt="signs-background"
        ></img>

        <p className="really-large-font center relative">About Us</p>
        <p className="center">
          Unsere Mission ist Bildung zugänglich & modern zu machen
        </p>
        <p></p>
      </section>
      <section className="more-top-margin">
        <div className="grid two">
          <img
            className="limited-width more-left-margin"
            src={require("./assets/Group 737.png")}
            alt="signs-background"
          ></img>
          <div>
            <p className="large-font">Signs School</p>
            <p className="italic text-margin-top medium">
              Vertreten durch Geschäftsführer: Maximilian Schnitzler
            </p>
            <p className="text-margin-more-top">
              Reichenhallerstraße 25 <br></br> 81547 München <br></br>
              hello@signsschool.de
            </p>
          </div>
        </div>
      </section>
      <section className="more-top-margin bottom-margin">
        <p className="left-margin small-bottom-margin medium-large">
          Buchen Sie noch heute ein kostenloses Gespräch mit unseren Experten
        </p>
        <a className="left-margin round-button black" onClick={handleContact}>
          Kontaktieren Sie uns
        </a>
      </section>
    </div>
  );
}
