import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  const handleContactClick = (async) => {
    navigate("/contact");
  };

  const handleAboutClick = (async) => {
    navigate("/about");
  };

  const handleTermsClick = (async) => {
    navigate("/terms");
  };

  const handleAppStoreClick = (async) => {
    window.open("");
  };

  const handleGooglePlayStoreClick = (async) => {
    window.open("");
  };

  const handleEmailClick = (async) => {};

  return (
    <div>
      <footer className="footer">
        <div>
          <div className="footer-link-container">
            <a
              onClick={() => handleAboutClick()}
              className="semi-bold more-top-margin"
            >
              &Uuml;ber uns
            </a>
            <a
              onClick={() => handleTermsClick()}
              className="semi-bold more-top-margin"
            >
              AGB
            </a>
            <a
              onClick={() => handleContactClick()}
              className="semi-bold more-top-margin"
            >
              Produkte
            </a>
          </div>
          <hr className="footer-separator"></hr>
          <div className="footer-container small-top-margin">
            <h5 className="footer-logo">Signs School</h5>
            <p className="footer-community-text bold text-margin-top">
              Werden Sie Teil <br></br> unserer Schulcommunity.
            </p>

            <div>
              <input
                type="text"
                className="footer-input top-margin"
                placeholder="Was ist Ihre Email?"
              ></input>
              <button
                onClick={() => handleEmailClick()}
                className="button light small light-border"
              >
                Starten
              </button>
            </div>
          </div>

          <hr className="footer-separator top-margin"></hr>
          <div className="links-container">
            <div className="socials-box small-top-margin">
              <img
                className="social-badge"
                src={require("./assets/twitter-icon-gray.png")}
              />
              <img
                className="social-badge"
                src={require("./assets/instagram-icon-gray.png")}
              />
            </div>
            <div className="apps-box">
              <img
                onClick={() => handleAppStoreClick()}
                className="app-badge why-margin"
                src={require("./assets/apple-appstore-badge-min.png")}
              />
              <img
                onClick={() => handleGooglePlayStoreClick()}
                className="app-badge why-margin"
                src={require("./assets/google-play-badge-2-min.png")}
              />
              <img
                className="web-app-badge"
                src={require("./assets/signs-web-app-icon.png")}
              />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
