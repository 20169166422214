import { useNavigate } from "react-router-dom";

export default function NavBar() {
  const navigate = useNavigate();

  const handleContactClick = (async) => {
    navigate("/contact");
  };

  const handleFeaturesClick = (async) => {
    window.scrollTo(0, 2400);
  };

  return (
    <div>
      <nav className="navbar background">
        <ul className="nav-list small-top-margin">
          <div className="logo">
            <img src={require("./assets/signsschool_logo.png")} />
          </div>
          <ul className="navigation">
            <li>
              <a href="./anfragen">Produkte</a>
            </li>
            <li>
              <a onClick={() => handleFeaturesClick()}>Funktionen</a>
            </li>
          </ul>
        </ul>

        <div className="rightNav">
          <button
            onClick={() => handleContactClick()}
            className="cpa-button-light full-width top-margin"
          >
            Anfragen
          </button>
        </div>
      </nav>
    </div>
  );
}
