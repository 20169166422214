import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import Hours from "./hours";
import Attendance from "./attendance";
import Assignments from "./assignments";
import Id from "./id";
import Signs from "./signs";
import About from "./about";
import Contact from "./contact";
import Terms from "./terms";
import { Route, Routes } from "react-router-dom";
import "./index.css";
import ScrollToTop from "./scrollToTop";
import NavBar from "./navigationBar";
import Mobile from "./mobile";
import Footer from "./footer";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HashRouter>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path="/" element={<Contact />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/id" element={<Id />} />
        <Route path="/signs" element={<Signs />} />
        <Route path="/assignments" element={<Assignments />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/hours" element={<Hours />} />
      </Routes>
      <Footer />
    </HashRouter>
  </React.StrictMode>
);
