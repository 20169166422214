import { useNavigate } from "react-router-dom";

export default function Id() {

  const navigate = useNavigate();

  const handleContactClick = (async) => {
    navigate("/contact");
  };

  return (
    <div>
      <section className="bottom-margin">
        <img
          className="extended-width absolute"
          src={require("./assets/pointed-background.png")}
          alt="id-background"
        ></img>
        <div className="relative">
          <div className="grid two">
            <div className="even-more-left-margin even-more-top-margin">
              <p className="medium bold really-large-font text-margin-top left-align small-bottom-margin">
                Der erste digitale <br></br> Schülerausweis
              </p>
              <a onClick={() => handleContactClick()} className="round-button black">Go Digital</a>
            </div>

            <img
              className="small-image even-more-left-margin even-more-top-margin"
              src={require("./assets/signs-id.png")}
              alt="signs-id"
            ></img>
          </div>
        </div>
      </section>
      <section className="even-more-top-margin">
        <div className="grid two left-margin ninth-width semi-height">
          <div className="black-background ninth-width border-radius">
            <p className="white bold really-large left-margin text-margin-more-top">
              SCHULE
            </p>
            <p className="white left-margin limited-width text-small-margin-top">
              Verwalten Sie einfach die Schüler an Ihrer Schule.
            </p>
            <img
              className="reduced-width more-left-margin top-margin"
              src={require("./assets/signs-id-manage.png")}
              alt="manage"
            ></img>
          </div>
          <div className="black-background ninth-width clay-height border-radius more-left-margin">
            <p className="white bold really-large left-margin text-margin-more-top">
              SCHÜLER
            </p>
            <p className="white left-margin limited-width text-small-margin-top">
              Den digitalen Schülerausweis von Signs hast du immer dabei. Nutze
              einfach den QR-Code, den jeder einscannen kann um die ID zu
              verifizieren.
            </p>
            <img
              className="fourfth-width centric more-top-margin"
              src={require("./assets/signs-id-qr.png")}
              alt="qr"
            ></img>
          </div>
        </div>
      </section>
      <section className="even-more-top-margin">
        <div className="black-background ninth-width left-margin fixed-half-height grid two-thirds">
          <div>
            <p className="white left-margin text-margin-top">
              SCHULLEITER AUS DÜSSELDORF
            </p>
            <p className="white left-margin medium-large semi-bold">
              Wie die Signs-ID für uns Zeit spart
            </p>
            <p className="white left-margin text-small-margin-top">
              “Unsere Schule hat zuvor viel Zeit damit verbracht Akten zu
              pflegen und Informationen bei Bedarf rauszusuchen. Besonders bei
              den Schülerausweisen mussten wir jedes Jahr Fotografen
              organisieren und die Ausweise in den Druck geben. Mit der Signs-ID
              gibt es das so nicht mehr. Die Schüler laden ein Bild hoch und das
              wars. Das ist nicht nur gut für uns weil wir uns nicht mehr um das
              Austeilen und Einsammeln der Ausweise kümmern müssen, sondern auch
              für Eltern & Schüler, weil sie nicht mehr für die Ausweise zahlen
              müssen.”
            </p>
          </div>
          <img
            className="fourfth-width centric more-top-margin block "
            src={require("./assets/shield-dynamic-clay.png")}
            alt="shield"
          ></img>
        </div>
      </section>
      <section className="even-more-top-margin">
        <div className="red-background fixed-seventh-height grid two">
          <img
            className="limited-width even-more-top-margin more-left-margin"
            src={require("./assets/signs-description-benefits.png")}
            alt="description"
          ></img>
          <img
            className="limited-width even-more-top-margin"
            src={require("./assets/signs-id-save-benefit.png")}
            alt="save-benefit"
          ></img>
        </div>
      </section>
      <section className="even-more-top-margin bottom-margin">
        <div className="border limited-width medium-left-margin grid two-thirds fixed-small-height border-radius">
          <div className="more-left-margin top-margin">
            <p className="really-large bold">Probieren Sie Signs aus</p>
            <p>Noch heute einen Termin vereinbaren.</p>
          </div>
          <a onClick={() => handleContactClick()} className="round-button black fourfth-width small-height even-more-top-margin">Hier starten</a>
        </div>
      </section>
    </div>
  );
}
