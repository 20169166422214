import { useNavigate } from "react-router-dom";

export default function Terms() {
  const navigate = useNavigate();

  const handleContactClick = (async) => {
    navigate("/contact");
  };



  return (
    <div>
      <section>
        <p className="more-top-margin really-large third-left-margin">
          Allgemeine Geschäftsbedingungen & Datenschutz
        </p>
        <p className="small-top-margin third-left-margin sixth-width">
          Wir freuen uns sehr über Ihr Interesse an Signs School (“Signs”,
          “wir”, “uns”) und unserer website signs.school und unseren weiteren
          Websites. Eine Nutzung der Internetseiten von Signs ist grundsätzlich
          ohne jede Angabe personenbezogener Daten möglich. Sofern eine
          betroffene Person besondere Services unseres Unternehmens über unsere
          Internetseite in Anspruch nehmen möchte, könnte jedoch eine
          Verarbeitung personenbezogener Daten erforderlich werden. Ist die
          Verarbeitung personenbezogener Daten erforderlich und besteht für eine
          solche Verarbeitung keine gesetzliche Grundlage, holen wir generell
          eine Einwilligung der betroffenen Person ein
        </p>

        <p className="top-margin medium-large semi-bold third-left-margin sixth-width">
          Kurze Version
        </p>
        <p className="small-top-margin third-left-margin sixth-width small-bottom-margin">
          Da Signs School, unsere Apps und Website in Schulen und im Umgang mit
          Jugendlichen und Kindern verwendet werden legen wir einen grossen Wert
          auf Datenschutz. <br></br>
          <br></br>
          Deshalb geben wir keine persönlichen Daten weiter an Dritte oder
          verwenden diese zu Werbezwecken. Lesen Sie für Details die
          vollständigen AGB und Datenschutzerklärung oder sprechen Sie uns bei
          Fragen direkt an.
        </p>
        <a
          onClick={() => handleContactClick()}
          className="round-button black third-left-margin"
        >
          Kontaktieren Sie uns
        </a>

        <p className="more-top-margin medium-large semi-bold third-left-margin sixth-width">
          Geltungsbereich und Anbieter
        </p>

        <p className="small-top-margin third-left-margin sixth-width">
          Für die Geschäftsbeziehung zwischen dem Anbieter und dem Kunden gelten
          ausschliesslich die nachfolgenden Allgemeinen Geschäftsbedingungen in
          ihrer zum Zeitpunkt der Bestellung gültigen Fassung. Abweichende
          allgemeine Geschäftsbedingungen des Kunden werden nicht anerkannt, es
          sei denn, der Anbieter stimmt ihrer Geltung ausdrücklich schriftlich
          zu. <br></br>
          <br></br>Der Kunde ist Verbraucher, soweit der Zweck der georderten
          Lieferungen und Leistungen nicht überwiegend seiner gewerblichen oder
          selbständigen beruflichen Tätigkeit zugerechnet werden kann. Dagegen
          ist Unternehmer jede natürliche oder juristische Person oder
          rechtsfähige Personengesellschaft, die beim Abschluss des Vertrags in
          Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit
          handelt.
        </p>
        <p className="top-margin medium third-left-margin sixth-width">
          Vertragsschluss
        </p>
        <p className="small-top-margin third-left-margin sixth-width">
          Der Vertragsschluss entsteht, sobald der Kunde auf signs.school eines
          der Abonnements ausgewählt und durch den Button "Account erstellen"
          einen Accounttyp ausgewählt hat.
        </p>
        <p className="top-margin medium third-left-margin sixth-width">
          Zahlungsmethoden
        </p>
        <p className="small-top-margin third-left-margin sixth-width">
          Wir akzeptieren grundsätzlich Zahlungen mit Kreditkarte, per
          Bankeinzug und PayPal. Ausnahmen sind bei Vereinbarung durch
          ausdrückliche schriftliche Zustimmung von SIGNS möglich.
        </p>

        <p className="top-margin medium third-left-margin sixth-width">
          Fälligkeit der Zahlung
        </p>

        <p className="small-top-margin third-left-margin sixth-width">
          Die Zahlung wird mit Vertragsabschluss fällig und ist ohne Abzug
          innerhalb von 30 Tagen zahlbar. Der Kunde kommt spätestens in Verzug,
          wenn er nicht innerhalb von 30 Tagen nach Fälligkeit und Zugang der
          Rechnung leistet.
        </p>

        <p className="top-margin medium third-left-margin sixth-width">
          Zahlungsverzug und Sperrung
        </p>

        <p className="small-top-margin third-left-margin sixth-width">
          Im Falle eines Zahlungsverzuges fallen Gebühren in Höhe von 4€ an.
          SIGNS ist weiterhin berechtigt nach erfolgloser Mahnung den Account
          Zugang zu entziehen.
        </p>

        <p className="top-margin medium third-left-margin sixth-width">
          Widerruf
        </p>
        <p className="small-top-margin third-left-margin sixth-width">
          Mit Vertragsschluss stimmt der Kunde der Vertragsausführung vor der
          Widerrufsfrist zu und verliert somit sein Widerrufsrecht des
          entstandenen Vertrags.
        </p>
        <p className="top-margin medium third-left-margin sixth-width">
          Haftungsausschluss
        </p>
        <p className="small-top-margin third-left-margin sixth-width">
          SIGNS haftet für Vorsatz und grobe Fahrlässigkeit unabhängig vom
          Rechtsgrund der Haftung. Die Haftung bei leichter Fahrlässigkeit ist
          ausgeschlossen.
        </p>

        <p className="top-margin third-left-margin sixth-width">
          Die Verarbeitung personenbezogener Daten, beispielsweise des Namens,
          der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen
          Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung
          und in Ãœbereinstimmung mit den für SIGNS geltenden landesspezifischen
          Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte
          unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der
          von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
          informieren. Ferner werden betroffene Personen mittels dieser
          Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
          SIGNS hat als für die Verarbeitung Verantwortlicher zahlreiche
          technische und organisatorische Massnahmen umgesetzt, um einen
          möglichst lückenlosen Schutz der über diese Internetseite
          verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können
          Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken
          aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden
          kann. Aus diesem Grund steht es jeder betroffenen Person frei,
          personenbezogene Daten auch auf alternativen Wegen, beispielsweise
          telefonisch, an uns zu übermitteln.
        </p>

        <p className="top-margin medium third-left-margin sixth-width">
          Begriffsbestimmungen
        </p>

        <p className="top-margin third-left-margin sixth-width">
          Die Datenschutzerklärung von SIGNS beruht auf den Begrifflichkeiten,
          die durch den Europüischen Richtlinien- und Verordnungsgeber beim
          Erlass der Datenschutz-Grundverordnung DSGVO verwendet wurden. Unsere
          Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für
          unsere Kunden und Geschäftspartner einfach lesbar und verständlich
          sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten
          Begrifflichkeiten erläutern. Wir verwenden in dieser
          Datenschutzerklürung unter anderem die folgenden Begriffe:
        </p>

        <p className="top-margin medium third-left-margin sixth-width semi-bold">
          personenbezogene Daten
        </p>
        <p className="top-margin third-left-margin sixth-width">
          Personenbezogene Daten sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person (im Folgenden
          betroffene Person) beziehen. Als identifizierbar wird eine natürliche
          Person angesehen, die direkt oder indirekt, insbesondere mittels
          Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
          Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren
          besonderen Merkmalen, die Ausdruck der physischen, physiologischen,
          genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
          Identität dieser natürlichen Person sind, identifiziert werden kann.
        </p>
        <p className="top-margin medium third-left-margin sixth-width semi-bold">
          betroffene Person
        </p>
        <p className="small-top-margin third-left-margin sixth-width">
          Betroffene Person ist jede identifizierte oder identifizierbare
          natürliche Person, deren personenbezogene Daten von dem für die
          Verarbeitung Verantwortlichen verarbeitet werden.
        </p>

        <p className="small-top-margin third-left-margin sixth-width">
          Verarbeitung <br></br> <br></br>Verarbeitung ist jeder mit oder ohne
          Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
          Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das
          Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung,
          die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
          Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine
          andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
          Einschränkung, das Löschen oder die Vernichtung. <br></br> <br></br>
          Einschränkung der Verarbeitung <br></br> <br></br>Einschränkung der
          Verarbeitung ist die Markierung gespeicherter personenbezogener Daten
          mit dem Ziel, ihre künftige Verarbeitung einzuschränken. <br></br>{" "}
          <br></br>Profiling <br></br> <br></br>Profiling ist jede Art der
          automatisierten Verarbeitung personenbezogener Daten, die darin
          besteht, dass diese personenbezogenen Daten verwendet werden, um
          bestimmte persönliche Aspekte, die sich auf eine natürliche Person
          beziehen, zu bewerten, insbesondere, um Aspekte bezüglich
          Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher
          Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
          Ortswechsel dieser natürlichen Person zu analysieren oder
          vorherzusagen. <br></br> <br></br>Pseudonymisierung <br></br>
          <br></br>Pseudonymisierung ist die Verarbeitung personenbezogener
          Daten in einer Weise, auf welche die personenbezogenen Daten ohne
          Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen
          betroffenen Person zugeordnet werden können, sofern diese zusätzlichen
          Informationen gesondert aufbewahrt werden und technischen und
          organisatorischen MaÃŸnahmen unterliegen, die gewährleisten, dass die
          personenbezogenen Daten nicht einer identifizierten oder
          identifizierbaren natürlichen Person zugewiesen werden.
          Verantwortlicher oder für die Verarbeitung Verantwortlicher <br></br>
          <br></br>Verantwortlicher oder für die Verarbeitung Verantwortlicher
          ist die natürliche oder juristische Person, Behörde, Einrichtung oder
          andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke
          und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
          Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht
          oder das Recht der Mitgliedstaaten vorgegeben, so kann der
          Verantwortliche beziehungsweise können die bestimmten Kriterien seiner
          Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
          vorgesehen werden.
        </p>
        <p className="top-margin third-left-margin sixth-width">
          Auftragsverarbeiten <br></br>
          <br></br>Auftragsverarbeiter ist eine natürliche oder juristische
          Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene
          Daten im Auftrag des Verantwortlichen verarbeitet.<br></br>
          <br></br>Empfänger<br></br>
          <br></br>Empfänger ist eine natürliche oder juristische Person,
          Behörde, Einrichtung oder andere Stelle, der personenbezogene Daten
          offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
          Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten
          Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
          Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
          jedoch nicht als Empfänger.<br></br>
          <br></br>Dritter<br></br>
          <br></br>Dritter ist eine natürliche oder juristische Person, Behörde,
          Einrichtung oder andere Stelle ausser der betroffenen Person, dem
          Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter
          der unmittelbaren Verantwortung des Verantwortlichen oder des
          Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
          verarbeiten.<br></br>
          <br></br>Einwilligung<br></br>
          <br></br>Einwilligung ist jede von der betroffenen Person freiwillig
          für den bestimmten Fall in informierter Weise und unmissverständlich
          abgegebene Willensbekundung in Form einer Erklärung oder einer
          sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene
          Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
          betreffenden personenbezogenen Daten einverstanden ist.<br></br>
          <br></br>2. Name und Anschrift des für die Verarbeitung
          Verantwortlichen<br></br>
          <br></br>Verantwortlicher im Sinne der Datenschutz-Grundverordnung,
          sonstiger in den Mitgliedstaaten der Europäischen Union geltenden
          Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem
          Charakter ist:<br></br>
          <br></br>signs school<br></br>Maximilian Schnitzler<br></br>
          Konrad-Adenauer-Platz 1<br></br>40210 Düsseldorf<br></br>Deutschland
          <br></br>E-Mail: hello@signsschool.de<br></br>Website:
          www.signs.school<br></br>
          <br></br>3. Cookies<br></br>
          <br></br>Die Internetseiten von SIGNS verwenden Cookies. Cookies sind
          Textdateien, welche über einen Internetbrowser auf einem
          Computersystem abgelegt und gespeichert werden.<br></br>
          <br></br>Zahlreiche Internetseiten und Server verwenden Cookies. Viele
          Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine
          eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge,
          durch welche Internetseiten und Server dem konkreten Internetbrowser
          zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies
          ermöglicht es den besuchten Internetseiten und Servern, den
          individuellen Browser der betroffenen Person von anderen
          Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein
          bestimmter Internetbrowser kann über die eindeutige Cookie-ID
          wiedererkannt und identifiziert werden.<br></br>
          <br></br>Durch den Einsatz von Cookies kann SIGNS den Nutzern dieser
          Internetseite nutzerfreundlichere Services bereitstellen, die ohne die
          Cookie-Setzung nicht möglich wären.<br></br>
          <br></br>Mittels eines Cookies können die Informationen und Angebote
          auf unserer Internetseite im Sinne des Benutzers optimiert werden.
          Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer unserer
          Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es,
          den Nutzern die Verwendung unserer Internetseite zu erleichtern. Der
          Benutzer einer Internetseite, die Cookies verwendet, muss
          beispielsweise nicht bei jedem Besuch der Internetseite erneut seine
          Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem
          Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein
          weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der
          Online-Shop merkt sich die Artikel, die ein Kunde in den virtuellen
          Warenkorb gelegt hat, über ein Cookie.<br></br>
          <br></br>Die betroffene Person kann die Setzung von Cookies durch
          unsere Internetseite jederzeit mittels einer entsprechenden
          Einstellung des genutzten Internetbrowsers verhindern und damit der
          Setzung von Cookies dauerhaft widersprechen. Ferner können bereits
          gesetzte Cookies jederzeit über einen Internetbrowser oder andere
          Softwareprogramme gelöscht werden. Dies ist in allen gängigen
          Internetbrowsern möglich. Deaktiviert die betroffene Person die
          Setzung von Cookies in dem genutzten Internetbrowser, sind unter
          Umständen nicht alle Funktionen unserer Internetseite vollumfänglich
          nutzbar.<br></br>
          <br></br>
          <br></br>4. Erfassung von allgemeinen Daten und Informationen<br></br>
          <br></br>Die Internetseite von SIGNS erfasst mit jedem Aufruf der
          Internetseite durch eine betroffene Person oder ein automatisiertes
          System eine Reihe von allgemeinen Daten und Informationen. Diese
          allgemeinen Daten und Informationen werden in den Logfiles des Servers
          gespeichert. Erfasst werden können die (1) verwendeten Browsertypen
          und Versionen, (2) das vom zugreifenden System verwendete
          Betriebssystem, (3) die Internetseite, von welcher ein zugreifendes
          System auf unsere Internetseite gelangt (sogenannte Referrer), (4) die
          Unterwebseiten, welche über ein zugreifendes System auf unserer
          Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines
          Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse
          (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden
          Systems und (8) sonstige ähnliche Daten und Informationen, die der
          Gefahrenabwehr im Falle von Angriffen auf unsere
          informationstechnologischen Systeme dienen. Bei der Nutzung dieser
          allgemeinen Daten und Informationen zieht SIGNS keine Rückschlüsse auf
          die betroffene Person. Diese Informationen werden vielmehr benötigt,
          um (1) die Inhalte unserer Internetseite korrekt auszuliefern, (2) die
          Inhalte unserer Internetseite sowie die Werbung fÃ¼r diese zu
          optimieren, (3) die dauerhafte Funktionsfähigkeit unserer
          informationstechnologischen Systeme und der Technik unserer
          Internetseite zu gewährleisten sowie (4) um StrafverfolgungsbehÃ¶rden
          im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen
          Informationen bereitzustellen. Diese anonym erhobenen Daten und
          Informationen werden durch SIGNS daher einerseits statistisch und
          ferner mit dem Ziel ausgewertet, den Datenschutz und die
          Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein
          optimales Schutzniveau für die von uns verarbeiteten personenbezogenen
          Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden
          getrennt von allen durch eine betroffene Person angegebenen
          personenbezogenen Daten gespeichert.<br></br>
          <br></br>5. Kontaktmöglichkeit über die Internetseite<br></br>
          <br></br>Die Internetseite von SIGNS enthält aufgrund von gesetzlichen
          Vorschriften Angaben, die eine schnelle elektronische Kontaktaufnahme
          zu unserem Unternehmen sowie eine unmittelbare Kommunikation mit uns
          ermöglichen, was ebenfalls eine allgemeine Adresse der sogenannten
          elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine betroffene
          Person per E-Mail oder über ein Kontaktformular den Kontakt mit dem
          für die Verarbeitung Verantwortlichen aufnimmt, werden die von der
          betroffenen Person übermittelten personenbezogenen Daten automatisch
          gespeichert. Solche auf freiwilliger Basis von einer betroffenen
          Person an den für die Verarbeitung Verantwortlichen übermittelten
          personenbezogenen Daten werden für Zwecke der Bearbeitung oder der
          Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine
          Weitergabe dieser personenbezogenen Daten an Dritte.<br></br>
          <br></br>6. Routinemässige Löschung und Sperrung von personenbezogenen
          Daten<br></br>
          <br></br>Der für die Verarbeitung Verantwortliche verarbeitet und
          speichert personenbezogene Daten der betroffenen Person nur für den
          Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist
          oder sofern dies durch den Europäischen Richtlinien- und
          Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder
          Vorschriften, welchen der für die Verarbeitung Verantwortliche
          unterliegt, vorgesehen wurde. Entfällt der Speicherungszweck oder
          läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder
          einem anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist
          ab, werden die personenbezogenen Daten routinemässig und entsprechend
          den gesetzlichen Vorschriften gesperrt oder gelöscht.<br></br>
          <br></br>7. Rechte der betroffenen Person<br></br>
          <br></br>Recht auf Bestätigung<br></br>
          <br></br>Jede betroffene Person hat das vom Europäischen Richtlinien-
          und Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
          Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie
          betreffende personenbezogene Daten verarbeitet werden. Möchte eine
          betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann
          sie sich hierzu jederzeit an einen Mitarbeiter des für die
          Verarbeitung Verantwortlichen wenden.<br></br>
          <br></br>Recht auf Auskunft<br></br>
          <br></br>die Verarbeitungszwecke<br></br>
          <br></br>die Kategorien personenbezogener Daten, die verarbeitet
          werden<br></br>
          <br></br>die Empfänger oder Kategorien von Empfängern, gegenüber denen
          die personenbezogenen Daten offengelegt worden sind oder noch
          offengelegt werden, insbesondere bei Empfängern in Drittländern oder
          bei internationalen Organisationen<br></br>
          <br></br>falls möglich die geplante Dauer, für die die
          personenbezogenen Daten gespeichert werden, oder, falls dies nicht
          möglich ist, die Kriterien für die Festlegung dieser Dauer<br></br>
          <br></br>das Bestehen eines Rechts auf Berichtigung oder Löschung der
          sie betreffenden personenbezogenen Daten oder auf Einschränkung der
          Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
          gegen diese Verarbeitung das Bestehen eines Beschwerderechts bei einer
          Aufsichtsbehörde<br></br>
          <br></br>wenn die personenbezogenen Daten nicht bei der betroffenen
          Person erhoben werden: Alle verfügbaren Informationen über die
          Herkunft der Daten<br></br>
          <br></br>das Bestehen einer automatisierten Entscheidungsfindung
          einschliesslich Profiling gemäss Artikel 22 Abs.1 und 4 DS-GVO und,
          zumindest in diesen Fällen, aussagekräftige Informationen über die
          involvierte Logik sowie die Tragweite und die angestrebten
          Auswirkungen einer derartigen Verarbeitung für die betroffene Person
          <br></br>
          <br></br>Jede von der Verarbeitung personenbezogener Daten betroffene
          Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
          gewährte Recht, jederzeit von dem für die Verarbeitung
          Verantwortlichen unentgeltliche Auskunft über die zu seiner Person
          gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft
          zu erhalten. Ferner hat der Europäische Richtlinien- und
          Verordnungsgeber der betroffenen Person Auskunft über folgende
          Informationen zugestanden: Ferner steht der betroffenen Person ein
          Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland
          oder an eine internationale Organisation übermittelt wurden. Sofern
          dies der Fall ist, so steht der betroffenen Person im Übrigen das
          Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit
          der Übermittlung zu erhalten. Möchte eine betroffene Person dieses
          Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an
          einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
        </p>
        <p className="small-top-margin third-left-margin sixth-width">
          Recht auf Berichtigung<br></br>
          <br></br>Jede von der Verarbeitung personenbezogener Daten betroffene
          Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
          gewährte Recht, die unverzügliche Berichtigung sie betreffender
          unrichtiger personenbezogener Daten zu verlangen. Ferner steht der
          betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der
          Verarbeitung, die Vervollständigung unvollständiger personenbezogener
          Daten” auch mittels einer ergänzenden Erklärung ” zu verlangen.
          <br></br>Möchte eine betroffene Person dieses Berichtigungsrecht in
          Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter
          des für die Verarbeitung Verantwortlichen wenden.<br></br>
          <br></br>Recht auf Löschung (Recht auf Vergessen werden)<br></br>
          <br></br>Jede von der Verarbeitung personenbezogener Daten betroffene
          Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
          gewährte Recht, von dem Verantwortlichen zu verlangen, dass die sie
          betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
          sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung
          nicht erforderlich ist:<br></br>
          <br></br>Sofern einer der oben genannten Gründe zutrifft und eine
          betroffene Person die Löschung von personenbezogenen Daten, die bei
          SIGNS gespeichert sind, veranlassen möchte, kann sie sich hierzu
          jederzeit an einen Mitarbeiter des für die Verarbeitung
          Verantwortlichen wenden. Der Mitarbeiter von SIGNS wird veranlassen,
          dass dem Löschverlangen unverzüglich nachgekommen wird.<br></br>
          <br></br>Wurden die personenbezogenen Daten von SIGNS öffentlich
          gemacht und ist unser Unternehmen als Verantwortlicher gemäss Art. 17
          Abs. 1 DS-GVO zur Löschung der personenbezogenen Daten verpflichtet,
          so trifft SIGNS unter Berücksichtigung der verfügbaren Technologie und
          der Implementierungskosten angemessene Massnahmen, auch technischer
          Art, um andere für die Datenverarbeitung Verantwortliche, welche die
          veröffentlichten personenbezogenen Daten verarbeiten, darüber in
          Kenntnis zu setzen, dass die betroffene Person von diesen anderen für
          die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links
          zu diesen personenbezogenen Daten oder von Kopien oder Replikationen
          dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung
          nicht erforderlich ist. Der Mitarbeiter von SIGNS wird im Einzelfall
          das Notwendige veranlassen.<br></br>
          <br></br>Die personenbezogenen Daten wurden für solche Zwecke erhoben
          oder auf sonstige Weise verarbeitet, für welche sie nicht mehr
          notwendig sind.<br></br>
          <br></br>Die betroffene Person widerruft ihre Einwilligung, auf die
          sich die Verarbeitung gemäss Art. 6 Abs. 1 Buchstabe a DS-GVO oder
          Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und es fehlt an einer
          anderweitigen Rechtsgrundlage für die Verarbeitung.<br></br>
          <br></br>Die betroffene Person legt gemäss Art. 21 Abs. 1 DS-GVO
          Widerspruch gegen die Verarbeitung ein, und es liegen keine
          vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die
          betroffene Person legt gemäss Art. 21 Abs. 2 DS-GVO Widerspruch gegen
          die Verarbeitung ein.<br></br>
          <br></br>Die personenbezogenen Daten wurden unrechtmässig verarbeitet.
          <br></br>
          <br></br>Die Löschung der personenbezogenen Daten ist zur Erfüllung
          einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht
          der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
          <br></br>
          <br></br>Die personenbezogenen Daten wurden in Bezug auf angebotene
          Dienste der Informationsgesellschaft gemäss Art. 8 Abs. 1 DS-GVO
          erhoben.<br></br>
          <br></br>Recht auf Einschränkung der Verarbeitung<br></br>
          <br></br>Jede von der Verarbeitung personenbezogener Daten betroffene
          Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
          gewährte Recht, von dem Verantwortlichen die Einschränkung der
          Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen
          gegeben ist: Sofern eine der oben genannten Voraussetzungen gegeben
          ist und eine betroffene Person die Einschränkung von personenbezogenen
          Daten, die bei SIGNS gespeichert sind, verlangen möchte, kann sie sich
          hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
          Verantwortlichen wenden. Der Mitarbeiter von SIGNS wird die
          Einschränkung der Verarbeitung veranlassen. Die Richtigkeit der
          personenbezogenen Daten wird von der betroffenen Person bestritten,
          und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die
          Richtigkeit der personenbezogenen Daten zu überprüfen. Die
          Verarbeitung ist unrechtmässig, die betroffene Person lehnt die
          Löschung der personenbezogenen Daten ab und verlangt stattdessen die
          Einschränkung der Nutzung der personenbezogenen Daten. Der
          Verantwortliche benötigt die personenbezogenen Daten für die Zwecke
          der Verarbeitung nicht länger, die betroffene Person benötigt sie
          jedoch zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen. Die betroffene Person hat Widerspruch gegen die
          Verarbeitung gem. Art. 21 Abs. 1 DS-GVO eingelegt und es steht noch
          nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber
          denen der betroffenen Person überwiegen.<br></br>
          <br></br>Recht auf Datenübertragbarkeit<br></br>
          <br></br>Jede von der Verarbeitung personenbezogener Daten betroffene
          Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
          gewährte Recht, die sie betreffenden personenbezogenen Daten, welche
          durch die betroffene Person einem Verantwortlichen bereitgestellt
          wurden, in einem strukturierten, gängigen und maschinenlesbaren Format
          zu erhalten. Sie hat auÃŸerdem das Recht, diese Daten einem anderen
          Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
          personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
          die Verarbeitung auf der Einwilligung gemäss Art. 6 Abs. 1 Buchstabe a
          DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag
          gemäss Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung
          mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung
          nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im
          öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
          erfolgt, welche dem Verantwortlichen übertragen wurde. Ferner hat die
          betroffene Person bei der Ausübung ihres Rechts auf
          Datenübertragbarkeit gemäss Art. 20 Abs. 1 DS-GVO das Recht, zu
          erwirken, dass die personenbezogenen Daten direkt von einem
          Verantwortlichen an einen anderen Verantwortlichen übermittelt werden,
          soweit dies technisch machbar ist und sofern hiervon nicht die Rechte
          und Freiheiten anderer Personen beeinträchtigt werden. Zur
          Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die
          betroffene Person jederzeit an einen Mitarbeiter von SIGNS wenden.
          <br></br>
          <br></br>Recht auf Widerspruch<br></br>
          <br></br>Jede von der Verarbeitung personenbezogener Daten betroffene
          Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
          gewährte Recht, aus Gründen, die sich aus ihrer besonderen Situation
          ergeben, jederzeit gegen die Verarbeitung sie betreffender
          personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e
          oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein
          auf diese Bestimmungen gestütztes Profiling. SIGNS verarbeitet die
          personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei
          denn, wir können zwingende schutzwürdige Gründe fÃ¼r die Verarbeitung
          nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen
          Person überwiegen, oder die Verarbeitung dient der Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen. Verarbeitet SIGNS
          personenbezogene Daten, um Direktwerbung zu betreiben, so hat die
          betroffene Person das Recht, jederzeit Widerspruch gegen die
          Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung
          einzulegen. Dies gilt auch fÃ¼r das Profiling, soweit es mit solcher
          Direktwerbung in Verbindung steht. Widerspricht die betroffene Person
          gegenüber SIGNS der Verarbeitung für Zwecke der Direktwerbung, so wird
          SIGNS die personenbezogenen Daten nicht mehr fÃ¼r diese Zwecke
          verarbeiten. Zudem hat die betroffene Person das Recht, aus Gründen,
          die sich aus ihrer besonderen Situation ergeben, gegen die sie
          betreffende Verarbeitung personenbezogener Daten, die bei SIGNS zu
          wissenschaftlichen oder historischen Forschungszwecken oder zu
          statistischen Zwecken gemäss Art. 89 Abs. 1 DS-GVO erfolgen,
          Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur
          Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
          erforderlich. Zur Ausübung des Rechts auf Widerspruch kann sich die
          betroffene Person direkt an jeden Mitarbeiter von SIGNS oder einen
          anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner
          frei, im Zusammenhang mit der Nutzung von Diensten der
          Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr
          Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
          denen technische Spezifikationen verwendet werden.<br></br>
          <br></br>
          <br></br>
        </p>
        <p className="small-top-margin third-left-margin sixth-width">
          Automatisierte Entscheidungen im Einzelfall einschliesslich Profiling
          <br></br>
          <br></br>Jede von der Verarbeitung personenbezogener Daten betroffene
          Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
          gewährte Recht, nicht einer ausschliesslich auf einer automatisierten
          Verarbeitung einschliesslich Profiling beruhenden Entscheidung
          unterworfen zu werden, die ihr gegenüber rechtliche Wirkung entfaltet
          oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die
          Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines
          Vertrags zwischen der betroffenen Person und dem Verantwortlichen
          erforderlich ist, oder (2) aufgrund von Rechtsvorschriften der Union
          oder der Mitgliedstaaten, denen der Verantwortliche unterliegt,
          zulässig ist und diese Rechtsvorschriften angemessene Massnahmen zur
          Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen
          der betroffenen Person enthalten oder (3) mit ausdrücklicher
          Einwilligung der betroffenen Person erfolgt. Ist die Entscheidung (1)
          für den Abschluss oder die Erfüllung eines Vertrags zwischen der
          betroffenen Person und dem Verantwortlichen erforderlich oder (2)
          erfolgt sie mit ausdrücklicher Einwilligung der betroffenen Person,
          trifft die SIGNS angemessene Massnahmen, um die Rechte und Freiheiten
          sowie die berechtigten Interessen der betroffenen Person zu wahren,
          wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person
          seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts
          und auf Anfechtung der Entscheidung gehört. Möchte die betroffene
          Person Rechte mit Bezug auf automatisierte Entscheidungen geltend
          machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
          die Verarbeitung Verantwortlichen wenden.<br></br>
          <br></br>Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
          <br></br>
          <br></br>Jede von der Verarbeitung personenbezogener Daten betroffene
          Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
          gewährte Recht, eine Einwilligung zur Verarbeitung personenbezogener
          Daten jederzeit zu widerrufen. Möchte die betroffene Person ihr Recht
          auf Widerruf einer Einwilligung geltend machen, kann sie sich hierzu
          jederzeit an einen Mitarbeiter des für die Verarbeitung
          Verantwortlichen wenden.<br></br>
          <br></br>9. Datenschutzbestimmungen zu Einsatz und Verwendung von
          Tools Dritter Anbieter<br></br>
          <br></br>Google-AdWords<br></br>
          <br></br>Der für die Verarbeitung Verantwortliche hat auf dieser
          Internetseite Google AdWords integriert. Google AdWords ist ein Dienst
          zur Internetwerbung, der es Werbetreibenden gestattet, sowohl Anzeigen
          in den Suchmaschinenergebnissen von Google als auch im
          Google-Werbenetzwerk zu schalten. Google AdWords ermöglicht es einem
          Werbetreibenden, vorab bestimmte Schlüsselwörter festzulegen, mittels
          derer eine Anzeige in den Suchmaschinenergebnissen von Google
          ausschliesslich dann angezeigt wird, wenn der Nutzer mit der
          Suchmaschine ein schlüsselwortrelevantes Suchergebnis abruft. Im
          Google-Werbenetzwerk werden die Anzeigen mittels eines automatischen
          Algorithmus und unter Beachtung der zuvor festgelegten Schlüsselwörter
          auf themenrelevanten Internetseiten verteilt. Betreibergesellschaft
          der Dienste von Google AdWords ist die Google Ireland Limited, Gordon
          House, Barrow Street, Dublin, D04 E5W5, Ireland. Der Zweck von Google
          AdWords ist die Bewerbung unserer Internetseite durch die Einblendung
          von interessenrelevanter Werbung auf den Internetseiten von
          Drittunternehmen und in den Suchmaschinenergebnissen der Suchmaschine
          Google und eine Einblendung von Fremdwerbung auf unserer
          Internetseite. Gelangt eine betroffene Person über eine Google-Anzeige
          auf unsere Internetseite, wird auf dem informationstechnologischen
          System der betroffenen Person durch Google ein sogenannter
          Conversion-Cookie abgelegt. Was Cookies sind, wurde oben bereits
          erläutert. Ein Conversion-Cookie verliert nach dreissig Tagen seine
          Gültigkeit und dient nicht zur Identifikation der betroffenen Person.
          Ãœber den Conversion-Cookie wird, sofern das Cookie noch nicht
          abgelaufen ist, nachvollzogen, ob bestimmte Unterseiten,
          beispielsweise der Warenkorb von einem Online-Shop-System, auf unserer
          Internetseite aufgerufen wurden. Durch den Conversion-Cookie können
          sowohl wir als auch Google nachvollziehen, ob eine betroffene Person,
          die über eine AdWords-Anzeige auf unsere Internetseite gelangt ist,
          einen Umsatz generierte, also einen Warenkauf vollzogen oder
          abgebrochen hat. Die durch die Nutzung des Conversion-Cookies
          erhobenen Daten und Informationen werden von Google verwendet, um
          Besuchsstatistiken für unsere Internetseite zu erstellen. Diese
          Besuchsstatistiken werden durch uns wiederum genutzt, um die
          Gesamtanzahl der Nutzer zu ermitteln, welche über AdWords-Anzeigen an
          uns vermittelt wurden, also um den Erfolg oder Misserfolg der
          jeweiligen AdWords-Anzeige zu ermitteln und um unsere AdWords-Anzeigen
          für die Zukunft zu optimieren. Weder unser Unternehmen noch andere
          Werbekunden von Google-AdWords erhalten Informationen von Google,
          mittels derer die betroffene Person identifiziert werden könnte.
          Mittels des Conversion-Cookies werden personenbezogene Informationen,
          beispielsweise die durch die betroffene Person besuchten
          Internetseiten, gespeichert. Bei jedem Besuch unserer Internetseiten
          werden demnach personenbezogene Daten, einschliesslich der IP-Adresse
          des von der betroffenen Person genutzten Internetanschlusses, an
          Google in den Vereinigten Staaten von Amerika übertragen. Diese
          personenbezogenen Daten werden durch Google in den Vereinigten Staaten
          von Amerika gespeichert. Google gibt diese über das technische
          Verfahren erhobenen personenbezogenen Daten unter Umständen an Dritte
          weiter. Die betroffene Person kann die Setzung von Cookies durch
          unsere Internetseite, wie oben bereits dargestellt, jederzeit mittels
          einer entsprechenden Einstellung des genutzten Internetbrowsers
          verhindern und damit der Setzung von Cookies dauerhaft widersprechen.
          Eine solche Einstellung des genutzten Internetbrowsers würde auch
          verhindern, dass Google einen Conversion-Cookie auf dem
          informationstechnologischen System der betroffenen Person setzt. Zudem
          kann ein von Google AdWords bereits gesetzter Cookie jederzeit über
          den Internetbrowser oder andere Softwareprogramme gelöscht werden.
          Ferner besteht für die betroffene Person die Möglichkeit, der
          interessenbezogenen Werbung durch Google zu widersprechen. Hierzu muss
          die betroffene Person von jedem der von ihr genutzten Internetbrowser
          aus den Link www.google.de/settings/ads aufrufen und dort die
          gewünschten Einstellungen vornehmen. Weitere Informationen und die
          geltenden Datenschutzbestimmungen von Google können unter
          https://www.google.de/intl/de/policies/privacy/abgerufen werden.
          <br></br>
          <br></br>
          <br></br>Hotjar<br></br>
          <br></br>Wir nutzen Hotjar, um die Bedürfnisse unserer Nutzer besser
          zu verstehen und das Angebot und die Erfahrung auf dieser Webseite zu
          optimieren. Mithilfe der Technologie von Hotjar bekommen wir ein
          besseres Verständnis von den Erfahrungen unserer Nutzer (z.B. wieviel
          Zeit Nutzer auf welchen Seiten verbringen, welche Links sie anklicken,
          was sie mögen und was nicht etc.) und das hilft uns, unser Angebot am
          Feedback unserer Nutzer auszurichten. Hotjar arbeitet mit Cookies und
          anderen Technologien, um Daten über das Verhalten unserer Nutzer und
          über ihre Endgeräte zu erheben, insbesondere IP Adresse des Geräts
          (wird während Ihrer Website-Nutzung nur in anonymisierter Form erfasst
          und gespeichert), BildschirmgröÃŸe, Gerätetyp (Unique Device
          Identifiers), Informationen über den verwendeten Browser, Standort
          (nur Land), zum Anzeigen unserer Webseite bevorzugte Sprache. Hotjar
          speichert diese Informationen in unserem Auftrag in einem
          pseudonymisierten Nutzerprofil. Hotjar ist es vertraglich verboten,
          die in unserem Auftrag erhobenen Daten zu verkaufen. Weitere
          Informationen finden Sie in unter dem Abschnitt `about Hotjar` auf
          Hotjars Hilfe-Seite."<br></br>
          <br></br>
        </p>
        <p className="small-top-margin third-left-margin sixth-width bottom-margin">
          Google Analytics<br></br>
          <br></br>Wir verwenden Google Analytics, um die Website-Nutzung zu
          analysieren. Die daraus gewonnenen Daten werden genutzt, um unsere
          Website sowie WerbemaÃŸnahmen zu optimieren.<br></br>
          <br></br>Google Analytics wird uns von Google Ireland Limited (Gordon
          House, Barrow Street, Dublin 4, Irland) bereitgestellt. Google
          verarbeitet die Daten zur Website-Nutzung in unserem Auftrag und
          verpflichtet sich vertraglich zu MaÃŸnahmen, um die Sicherheit und
          Vertraulichkeit der verarbeiteten Daten zu gewährleisten.<br></br>
          <br></br>Während Ihres Website-Besuchs werden u.a. folgende Daten
          aufgezeichnet:<br></br>
          <br></br>Aufgerufene Seiten Bestellungen inkl. des Umsatzes und der
          bestellten Produkte Die Erreichung von "Website-Zielen" (z.B.
          Kontaktanfragen und Newsletter-Anmeldungen) Ihr Verhalten auf den
          Seiten (beispielsweise Verweildauer, Klicks, Scrollverhalten) Ihr
          ungefährer Standort (Land und Stadt) Ihre IP-Adresse (in gekÃ¼rzter
          Form, sodass keine eindeutige Zuordnung möglich ist) Technische
          Informationen wie Browser, Internetanbieter, Endgerät und
          Bildschirmauflösung Herkunftsquelle Ihres Besuchs (d.h. über welche
          Website bzw. über welches Werbemittel Sie zu uns gekommen sind) Es
          werden niemals persönliche Daten wie Name, Anschrift oder Kontaktdaten
          an Google Analytics übertragen. Diese Daten werden an Server von
          Google in den USA übertragen. Wir weisen darauf hin, dass in den USA
          datenschutzrechtlich nicht das gleiche Schutzniveau wie innerhalb der
          EU garantiert werden kann.<br></br>
          <br></br>Google Analytics speichert Cookies in Ihrem Webbrowser für
          die Dauer von zwei Jahren seit Ihrem letzten Besuch. Diese Cookies
          enthaltene eine zufallsgenerierte User-ID, mit der Sie bei zukünftigen
          Website-Besuchen wiedererkannt werden können.<br></br>
          <br></br>Die aufgezeichneten Daten werden zusammen mit der
          zufallsgenerierten User-ID gespeichert, was die Auswertung pseudonymer
          Nutzerprofile ermöglicht. Diese nutzerbezogenen Daten werden
          automatisch nach 14 Monaten gelöscht. Sonstige Daten bleiben in
          aggregierter Form unbefristet gespeichert.<br></br>
          <br></br>Sollten Sie mit der Erfassung nicht einverstanden sein,
          können Sie diese mit der einmaligen Installation des Browser-Add-ons
          zur Deaktivierung von Google Analytics unterbinden oder durch das
          Ablehnen der Cookies über unseren Cookie-Einstellungs-Dialog.<br></br>
          <br></br>11. Zahlungsart: Datenschutzbestimmungen zu PayPal als
          Zahlungsart<br></br>
          <br></br>Der für die Verarbeitung Verantwortliche hat auf dieser
          Internetseite Komponenten von PayPal integriert. PayPal ist ein
          Online-Zahlungsdienstleister. Zahlungen werden über sogenannte
          PayPal-Konten abgewickelt, die virtuelle Privat- oder Geschäftskonten
          darstellen. Zudem besteht bei PayPal die Möglichkeit, virtuelle
          Zahlungen über Kreditkarten abzuwickeln, wenn ein Nutzer kein
          PayPal-Konto unterhält. Ein PayPal-Konto wird über eine E-Mail-Adresse
          geführt, weshalb es keine klassische Kontonummer gibt. PayPal
          ermöglicht es, Online-Zahlungen an Dritte auszulösen oder auch
          Zahlungen zu empfangen. PayPal übernimmt ferner Treuhänderfunktionen
          und bietet Käuferschutzdienste an. Die Europäische
          Betreibergesellschaft von PayPal ist die PayPal (Europe) S.a.r.l. et
          Cie, S.C.A., 22-24 Boulevard Royal L-2449, Luxemburg. WÃ¤hlt die
          betroffene Person während des Bestellvorgangs in unserem Online-Shop
          als Zahlungsmöglichkeit PayPal aus, werden automatisiert Daten der
          betroffenen Person an PayPal übermittelt. Mit der Auswahl dieser
          Zahlungsoption willigt die betroffene Person in die zur
          Zahlungsabwicklung erforderliche Übermittlung personenbezogener Daten
          ein. Bei den an PayPal übermittelten personenbezogenen Daten handelt
          es sich in der Regel um Vorname, Nachname, Adresse, Email-Adresse,
          IP-Adresse, Telefonnummer, Mobiltelefonnummer oder andere Daten, die
          zur Zahlungsabwicklung notwendig sind. Zur Abwicklung des
          Kaufvertrages notwendig sind auch solche personenbezogenen Daten, die
          im Zusammenhang mit der jeweiligen Bestellung stehen. Die Übermittlung
          der Daten bezweckt die Zahlungsabwicklung und die Betrugsprävention.
          Der für die Verarbeitung Verantwortliche wird PayPal personenbezogene
          Daten insbesondere dann übermitteln, wenn ein berechtigtes Interesse
          für die Übermittlung gegeben ist. Die zwischen PayPal und dem für die
          Verarbeitung Verantwortlichen ausgetauschten personenbezogenen Daten
          werden von PayPal unter Umständen an Wirtschaftsauskunfteien
          übermittelt. Diese Übermittlung bezweckt die Identitäts- und
          Bonitätsprüfung. PayPal gibt die personenbezogenen Daten
          gegebenenfalls an verbundene Unternehmen und Leistungserbringer oder
          Subunternehmer weiter, soweit dies zur Erfüllung der vertraglichen
          Verpflichtungen erforderlich ist oder die Daten im Auftrag verarbeitet
          werden sollen. Die betroffene Person hat die Möglichkeit, die
          Einwilligung zum Umgang mit personenbezogenen Daten jederzeit
          gegenüber PayPal zu widerrufen. Ein Widerruf wirkt sich nicht auf
          personenbezogene Daten aus, die zwingend zur (vertragsgemässen)
          Zahlungsabwicklung verarbeitet, genutzt oder übermittelt werden
          müssen. Die geltenden Datenschutzbestimmungen von PayPal können unter
          https://www.paypal.com/de/webapps/mpp/ua/privacy-full abgerufen
          werden.<br></br>
          <br></br>12. Rechtsgrundlage der Verarbeitung<br></br>
          <br></br>Art. 6 I lit. a DS-GVO dient unserem Unternehmen als
          Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine
          Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die
          Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags,
          dessen Vertragspartei die betroffene Person ist, erforderlich, wie
          dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für
          eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung
          oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art.
          6 I lit. b DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die
          zur Durchführung vorvertraglicher Massnahmen erforderlich sind, etwa
          in Fällen von Anfragen zur unseren Produkten oder Leistungen.
          Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch
          welche eine Verarbeitung von personenbezogenen Daten erforderlich
          wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so
          basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen
          Fällen könnte die Verarbeitung von personenbezogenen Daten
          erforderlich werden, um lebenswichtige Interessen der betroffenen
          Person oder einer anderen natürlichen Person zu schützen. Dies wäre
          beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt
          werden würde und daraufhin sein Name, sein Alter, seine
          Krankenkassendaten oder sonstige lebenswichtige Informationen an einen
          Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden
          müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d DS-GVO
          beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f
          DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren
          Verarbeitungsvorgänge, die von keiner der vorgenannten
          Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung
          eines berechtigten Interesses unseres Unternehmens oder eines Dritten
          erforderlich ist, sofern die Interessen, Grundrechte und
          Grundfreiheiten des Betroffenen nicht überwiegen. Solche
          Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil
          sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er
          vertrat insoweit die Auffassung, dass ein berechtigtes Interesse
          anzunehmen sein könnte, wenn die betroffene Person ein Kunde des
          Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DS-GVO).<br></br>
          <br></br>13. Berechtigte Interessen an der Verarbeitung, die von dem
          Verantwortlichen oder einem Dritten verfolgt werden<br></br>
          <br></br>Basiert die Verarbeitung personenbezogener Daten auf Artikel
          6 I lit. f DS-GVO ist unser berechtigtes Interesse die Durchführung
          unserer Geschäftstätigkeit zugunsten des Wohlergehens all unserer
          Mitarbeiter und unserer Anteilseigner.<br></br>
          <br></br>14. Dauer, für die die personenbezogenen Daten gespeichert
          werden<br></br>
          <br></br>Das Kriterium für die Dauer der Speicherung von
          personenbezogenen Daten ist die jeweilige gesetzliche
          Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden
          Daten routinemässig gelöscht, sofern sie nicht mehr zur
          Vertragserfüllung oder Vertragsanbahnung erforderlich sind.<br></br>
          <br></br>15. Gesetzliche oder vertragliche Vorschriften zur
          Bereitstellung der personenbezogenen Daten; Erforderlichkeit für den
          Vertragsabschluss; Verpflichtung der betroffenen Person, die
          personenbezogenen Daten bereitzustellen; mögliche Folgen der
          Nichtbereitstellung<br></br>
          <br></br>Wir klären Sie darüber auf, dass die Bereitstellung
          personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist (z.B.
          Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B.
          Angaben zum Vertragspartner) ergeben kann. Mitunter kann es zu einem
          Vertragsschluss erforderlich sein, dass eine betroffene Person uns
          personenbezogene Daten zur Verfügung stellt, die in der Folge durch
          uns verarbeitet werden müssen. Die betroffene Person ist
          beispielsweise verpflichtet uns personenbezogene Daten
          bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag
          abschliesst. Eine Nichtbereitstellung der personenbezogenen Daten
          hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht
          geschlossen werden könnte. Vor einer Bereitstellung personenbezogener
          Daten durch den Betroffenen muss sich der Betroffene an einen unserer
          Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen
          einzelfallbezogen darüber auf, ob die Bereitstellung der
          personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben
          oder für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung
          besteht, die personenbezogenen Daten bereitzustellen, und welche
          Folgen die Nichtbereitstellung der personenbezogenen Daten hätte.
          <br></br>
          <br></br>16. Datenverarbeitung außerhalb der Europäischen Union
          <br></br>
          <br></br>Mit Ausnahme der Verwendung von jQuery via
          ajax.googleapis.com findet eine Verarbeitung Ihrer personenbezogenen
          Daten in einem sog. Drittstaat auÃŸerhalb der Europäischen Union nicht
          statt.<br></br>
          <br></br>Im Hinblick auf den Einsatz des o. g. Google-Dienstes ist
          darauf hinzuweisen, dass das angemessene Datenschutzniveau von Google
          im Rahmen seiner Teilnahme am sog. â€žPrivacy Shieldâ€œ und den von
          Google zu Datenschutz und Datensicherheit getroffenen MaÃŸnahmen
          gewährleistet ist.<br></br>
          <br></br>Nutzung von ajax.googleapis.com für jQuery und jQueryUI
          <br></br>
          <br></br>Diese Seite nutzt Ajax und jQuery bzw. jQueryUI Technologien,
          wodurch eine Optimierung der Ladegeschwindigkeiten erzielt, sowie
          Netzwerk Anfragen gestellt werden. Diese werden von der Google LLC.,
          1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (â€žGoogleâ€œ)
          bereitgestellt Sollten Sie jQuery vorher auf einer anderen Seite vom
          Google CDN gebraucht haben, wird Ihr Browser auf die im Cache
          abgelegte Kopie zurückgreifen. Ist dies nicht zutreffend, werden die
          benötigten Daten von Google heruntergeladen.<br></br>
          <br></br>Zu diesem Zweck muss der von Ihnen verwendete Browser
          Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt
          Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website
          aufgerufen wurde. Die Nutzung des Google CDN erfolgt im Interesse
          einer Optimierung der Ladegeschwindigkeiten unserer Online-Angebote.
          Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
          f DSGVO dar.<br></br>
          <br></br>Weitere Informationen zu Google Web Fonts finden Sie unter
          https://developers.google.com/fonts/faq und in der
          Datenschutzerklärung von Google:
          https://www.google.com/policies/privacy/<br></br>
          <br></br>
          <br></br>17. Hosting<br></br>
          <br></br>Unsere Website wird im Internet von einem Dienstleister
          (Provider oder Hoster) zum Abruf bereitgestellt. Wir nutzen hierfür
          den Dienst von Bluehost Inc., 1500 N Priest Drive, Suite 200, Tempe,
          AZ 85281, USA. Wir haben mit unserem Provider einen
          Auftragsverarbeitungsvertrag abgeschlossen. Danach ist unser Provider
          verpflichtet, Ihre Daten nur in unserem Auftrag und nach unserer
          Weisung zu verarbeiten. Weitergehende Informationen zu der
          Datenverarbeitung bei unserem Provider finden Sie in dessen
          Datenschutzerklärung hier. Rechtsgrundlage für diese Datenverarbeitung
          sind zum einen unsere berechtigten Interessen gem. Art. 6 Abs. 1 lit.
          f DSGVO an der Bereitstellung und Nutzung unserer Website im Internet
          sowie soweit einschlägig auch die gesetzliche Erlaubnis zur
          Speicherung von Daten im Rahmen der Anbahnung eines
          Vertragsverhältnisses gem. Art. 6 Abs. 1 lit. b DSGVO.<br></br>
          <br></br>Unser Provider verarbeitet bei jeder Nutzung dieser Website
          Informationen, die sog. Server Log Dateien, die automatisch bei jedem
          Aufruf von Websites im Internet von Ihrem Browser übermittelt werden.
          Dies sind:<br></br>Ihre IP-Adresse<br></br>Typ und Version Ihres
          Browsers Hostname Besuchszeitpunkt die Website, von der aus Sie unsere
          Website besucht haben Name der aufgerufenen Website genauer Zeitpunkt
          des Aufrufes sowie die übertragene Datenmenge Diese Daten werden nur
          für statistische Zwecke verwendet und ermöglichen uns keine
          Identifikation von Ihnen als Nutzer.
        </p>
      </section>
    </div>
  );
}
