import axios from "axios";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      schoolname: "",
      city: "",
      show: false,
      message: "",
      numberOfStudents: 0,
    };

    this.render();
    this.handleFirstNameChange.bind(this);
    this.handleLastNameChange.bind(this);
    this.handleEmailChange.bind(this);
    this.handlePhoneNumberChange.bind(this);
    this.handleSchoolnameChange.bind(this);
    this.handleCityChange.bind(this);
    this.handleSendClick.bind(this);
  }

  handleFirstNameChange = (event) => {
    this.setState({
      firstName: event.target.value,
    });
  };

  handleLastNameChange = (event) => {
    this.setState({
      lastName: event.target.value,
    });
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  handlePhoneNumberChange = (event) => {
    this.setState({
      phoneNumber: event.target.value,
    });
  };

  handleSchoolnameChange = (event) => {
    this.setState({
      schoolname: event.target.value,
    });
  };

  handleCityChange = (event) => {
    this.setState({
      city: event.target.value,
    });
  };

  async handleSendClick(event) {
    //
    console.log("clicked");
    // setShow(!show);

    try {
      const response = axios.post(
        "https://e2ipjnagbg.execute-api.eu-central-1.amazonaws.com/default/leads",
        {
          firstName: String(this.state.firstName),
          lastName: String(this.state.lastName),
          email: String(this.state.email),
          phone: String(this.state.phoneNumber),
          schoolname: String(this.state.schoolname),
          city: String(this.state.city),
          studentsNumber: String(this.state.numberOfStudents),
          message: String(this.state.message),
        },
        {
          headers: {
            "Access-Control-Allow-Headers":
              "X-Requested-With, Content-Type, Origin, Accept, Access-Control-Allow-Methods, Access-Control-Allow-Origin",
            "Access-Control-Allow-Methods": "GET, OPTIONS, POST, DELETE, PUT",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/plain",
          },
        }
      );

      console.log(`axios response: ${JSON.stringify(response)}`);
    } catch (error) {
      console.log(`axios error: ${error}`);
    }

    //this.useNavigate("/about");
  }

  render() {
    return (
      <div>
        <section>
          <div className="grid two more-top-margin ninth-width left-margin more-bottom-margin">
            <div className="navy-background fixed-extreme-height border-radius">
              <p className="white more-top-margin semi-bold really-large reduced-width left-margin">
                Lassen Sie uns zusammen Bildung besser machen.
              </p>
              <p className="gray-color small-top-margin left-margin reduced-width">
                Kontaktieren Sie uns um ein kostenloses Erstgespräch für Ihre
                Schule zu vereinbaren.
              </p>
              <div className="left-margin more-top-margin">
                <div className="grid two ninth-width">
                  <input
                    className="form-input"
                    placeholder="Vorname"
                    value={this.state.firstName}
                    onChange={(event) => this.handleFirstNameChange(event)}
                  ></input>
                  <input
                    className="form-input left-margin"
                    placeholder="Nachname"
                    value={this.state.lastName}
                    onChange={(event) => this.handleLastNameChange(event)}
                  ></input>
                </div>
                <div className="grid two ninth-width top-margin">
                  <input
                    className="form-input"
                    placeholder="Email"
                    type="email"
                    value={this.state.email}
                    onChange={(event) => this.handleEmailChange(event)}
                  ></input>
                  <input
                    className="form-input left-margin"
                    placeholder="Telefonnummer"
                    value={this.state.phoneNumber}
                    onChange={(event) => this.handlePhoneNumberChange(event)}
                  ></input>
                </div>
                <div className="grid two ninth-width top-margin">
                  <input
                    className="form-input"
                    placeholder="Schulname"
                    value={this.state.schoolname}
                    onChange={(event) => this.handleSchoolnameChange(event)}
                  ></input>
                  <input
                    className="form-input left-margin"
                    placeholder="Stadt"
                    value={this.state.city}
                    onChange={(event) => this.handleCityChange(event)}
                  ></input>
                </div>
                <textarea
                  className="form-input top-margin ninth-width fixed-half-height text-small-margin-top"
                  placeholder="Ihre Nachricht an uns"
                ></textarea>
              </div>
              <p className="white left-margin more-top-margin">Schüleranzahl</p>
              <div className="left-margin top-margin bottom-margin">
                <input
                  type="radio"
                  id="100-200"
                  value="100-200"
                  defaultChecked={true}
                />
                <label htmlFor="100-200" className="white small-padding-left">
                  100-200
                </label>
                <br></br>
                <input
                  type="radio"
                  className="semi-top-margin"
                  id="200-500"
                  value="200-500"
                  defaultChecked={false}
                />
                <label htmlFor="200-500" className="white small-padding-left">
                  200-500
                </label>
                <br></br>
                <input
                  type="radio"
                  className="semi-top-margin"
                  id="500-1000"
                  value="500-1000"
                  defaultChecked={false}
                />
                <label htmlFor="500-1000" className="white small-padding-left">
                  500-1000
                </label>
                <br></br>
                <input
                  type="radio"
                  className="semi-top-margin"
                  id="1000+"
                  value="1000+"
                  defaultChecked={false}
                />
                <label htmlFor="1000+" className="white small-padding-left">
                  1000+
                </label>
              </div>
              <a
                className="round-button left-margin more-bottom-margin"
                onClick={() => this.handleSendClick()}
              >
                Abschicken
              </a>
              <p
                className={
                  this.state.show
                    ? "show-class white left-margin text-margin-top bottom-margin"
                    : "hide-class white left-margin text-margin-top bottom-margin"
                }
              >
                Danke, wir haben Ihre Nachricht erhalten und melden uns bald bei
                Ihnen.
              </p>
            </div>
            <div className="navy-background fixed-extreme-height border-radius left-margin">
              <p className="white more-top-margin semi-bold really-large reduced-width left-margin">
                Die ambitioniertesten Schulen vertrauen auf Signs.
              </p>
              <p className="white top-margin left-margin reduced-width">
                “Mit Signs konnten wir an unserer Schule viele Prozesse
                vereinfachen, sodass wir im Schulbetrieb täglich Zeit sparen.
                Unsere Schüler & Lehrer mögen die App und die Kommunikation über
                Signs macht es einfacher zu unterrichten aber auch zu lernen.
                Die Möglichkeit quasi all unsere Aktivitäten zu digitalisieren,
                erleichtert die Arbeit enorm und sorgt dafür, dass wir unseren
                Fokus auf Schüler und Ihre Leistungen legen können, was zuvor
                leider nur eingeschränkt möglich war.”
              </p>
              <p className="gray-color left-margin text-margin-top">
                SCHULLEITER AUS BERLIN
              </p>
              <img
                className="limited-width centric more-top-margin"
                src={require("./assets/Rectangle 3290.png")}
                alt="rectangle"
              ></img>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
