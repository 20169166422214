import { useNavigate } from "react-router-dom";

export default function Hours() {

  const navigate = useNavigate();

  const handleContactClick = (async) => {
    navigate("/contact");
  };

  return (
    <div>
    <section className="first-section">
      <div className="box-main grid two">
        <div>
          <p className="medium bold really-large-font left-align more-left-margin">
            Entdecke unseren <br></br> Stundenplan
          </p>
          <p className="gray semi-bold medium left-align more-left-margin bottom-margin">
            Mit E-Learning Integration & automatisch erstellt.
          </p>
          <a className="round-button black more-left-margin">Upgraden Sie Ihre Schule</a>
        </div>

        <img
          className="feature-image even-more-top-margin"
          src={require("./assets/Group 693.png")}
          alt="hours"
        ></img>
      </div>
      </section>
    <section className="even-more-top-margin more-bottom-margin relative">
        <img className="background-image"  src={require("./assets/Group 758.png")}
          alt="background">
        </img>
        <a onClick={() => handleContactClick()} className="round-button black third-width centric top-margin">Ohne Stress starten</a>
        <br></br>
    </section>
    <section className="even-more-top-margin">
        <h3 className="large-font left-margin">(Fast) Automatisch</h3>
        <p className="p limited-width medium">Melden sich Lehrer krank übernehmen wir die Änderungen direkt in den Stundenplan und versuchen einen Ersatz zu finden. Sollte dies nicht gelingen nehmen wir die Materialien, die der kranke Lehrer bereitgestellt hat und leiten sie an die Klasse weiter.</p>
        <img className="feature-image ninth-width small-top-margin bottom-margin" src={require("./assets/automatic-notification-image.png")}
          alt="notification">
        </img>
    </section>
    <section className="even-more-top-margin">
        <h3 className="large-font left-margin">Kinderleicht verwalten</h3>
        <img className="feature-image ninth-width small-top-margin bottom-margin" src={require("./assets/Group 698.png")}
          alt="hours">
        </img>

    </section>
    </div>
  );
}
