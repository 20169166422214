import { useNavigate } from "react-router-dom";

export default function Assignments() {

  const navigate = useNavigate()

  const handleContactClick = async => {
      navigate("/contact");
  }

  return (
    <div>
      <section className="first-section">
        <div className="box-main grid two">
          <p className="large-font bold even-more-left-margin">
            Mit Signs macht <br></br> das Lernen Spaß
          </p>

          <img
            className="feature-image even-more-top-margin half-width"
            src={require("./assets/assignments-feature-image.png")}
            alt="assignments"
          ></img>
        </div>
      </section>
      <section className="even-more-top-margin">
        <div className="assignments-background ninth-width border-radius left-margin grid two">
          <div>
            <p className="large-font bold more-left-margin text-margin-third">
              LERNEN IN <br></br> DREI SCHRITTEN
            </p>
          </div>
          <div className="vertical-align extended-height more-top-margin left-margin">
            <div className="limited-width relative">
              <img
                className="circle absolute left-margin top-margin"
                src={require("./assets/istockphoto-922759140-612x612 10.png")}
                alt="teacher"
              ></img>
              <p className="padding-left absolute white-background">
                Frau Berger stellt auf Signs Hausaufgaben für eine Klasse ein.
                Neben Beschreibungen kann sie einfach Bilder hochladen.
              </p>
            </div>
            <div className="limited-width relative">
              <img
                className="circle absolute left-margin top-margin"
                src={require("./assets/pexels-max-fischer-5212330 6.png")}
                alt="student"
              ></img>
              <p className="padding-left absolute white-background">
                Lilli und ihre Klasse erhalten die Aufgabe, können diese
                bearbeiten oder Rückfragen stellen und die Lösung einschicken.
              </p>
            </div>
            <div className="limited-width relative">
              <img
                className="circle absolute left-margin top-margin"
                src={require("./assets/istockphoto-922759140-612x612 10.png")}
                alt="teacher"
              ></img>
              <p className="padding-left absolute white-background">
                Frau Berger erhält die Lösungen der Aufgaben und kann diese
                bewerten und Noten vergeben.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="top-margin">
        <div className="grid two left-margin ninth-width semi-height">
          <div className="black-background ninth-width border-radius">
            <p className="white bold really-large left-margin text-margin-more-top">
              ENTSPANN DICH. <br></br> WIR DENKEN DRAN.
            </p>
            <p className="white left-margin limited-width text-small-margin-top">
              Signs erinnert dich rechtzeitig an deine Hausaufgaben. So vergisst
              du nicht, was du aufhast und bekommst keine schlechten Noten.
            </p>
            <img
              className="third-width centric top-margin block bottom-margin"
              src={require("./assets/clock-dynamic-clay.png")}
              alt="clock"
            ></img>
            <a onClick={() => handleContactClick()} className="round-button left-margin">Aufgaben nutzen</a>
          </div>
          <div className="black-background ninth-width clay-height border-radius more-left-margin">
            <p className="white bold really-large left-margin text-margin-more-top">
              WISSEN WANN ZEIT IST
            </p>
            <p className="white left-margin limited-width text-small-margin-top">
              Signs hält Abgaben für dich im Auge. Du musst dir nicht
              aufschreiben, welche Hausaufgaben es gibt und bis wann du sie
              erledigen musst.
            </p>
            <img
              className="fourfth-width centric more-top-margin block more-bottom-margin"
              src={require("./assets/Group 725.png")}
              alt="calendar"
            ></img>
            <a onClick={() => handleContactClick()} className="round-button left-margin">Start</a>
          </div>
        </div>
      </section>
      <section className="more-top-margin">
        <div className="grid two">
          <img
            className="limited-width more-left-margin border-radius"
            src={require("./assets/pexels-katerina-holmes-5905509 3.png")}
            alt="clock"
          ></img>
          <div>
            <p className="large-font bold text-margin-half-top">
              VERWALTEN & <br></br> VERBESSERN
            </p>
            <p className="limited-width bottom-margin">
              Wir machen mehr aus E-Learning als Verwaltung. Signs macht das
              Lernen einfacher und wirkt aktiv auf die Verbesserung von Noten
              der Schüler hin.{" "}
            </p>
          </div>
        </div>
      </section>
      <section className="more-top-margin">
        <div className="ninth-width clay-background clay-height grid two left-margin border-radius half-height">
          <div>
            <p className="white large-font bold text-margin-half-top padding-left">
              FÜR LEHRER
            </p>
            <p className="white limited-width padding-left bottom-margin">
              Sehen Sie einfach, welche Schüler die Aufgabe abgegeben haben und
              bewerten Sie diese mit wenigen Klicks. Wir speichern Ihre
              Bewertungen, Noten oder Notizen und machen Sie einfach für Sie
              verwaltbar.
            </p>
            <a onClick={() => handleContactClick()} className="round-button even-more-left-margin half-top-margin">
              Signs an Ihrer Schule einsetzen
            </a>
          </div>
          <img
            className="half-width more-left-margin more-top-margin"
            src={require("./assets/location-dynamic-clay.png")}
            alt="arrow"
          ></img>
        </div>
      </section>
      <section className="more-top-margin">
        <div className="ninth-width clay-background clay-height grid two left-margin border-radius half-height">
          <img
            className="half-width more-left-margin more-top-margin"
            src={require("./assets/file-plus-iso-color.png")}
            alt="tests"
          ></img>
          <div>
            <p className="white large-font bold text-margin-half-top">TESTS</p>
            <p className="white limited-width bottom-margin">
              Mit der Test Funktion können Sie als Lehrer ganz einfach das
              Wissen Ihrer Klasse abfragen. Mit wenigen Klicks stellen Sie die
              Fragen und erhalten nach der Bearbeitung durch die Schüler die
              Lösungen, die Sie bewerten können.
            </p>
            <a onClick={() => handleContactClick()} className="round-button top-margin">
              Signs an Ihrer Schule einsetzen
            </a>
          </div>
        </div>
      </section>
      <section className="more-top-margin">
        <p className="center large-font">Mit Signs macht Lernen Spaß</p>
        <a onClick={() => handleContactClick()} className="round-button third-width black centric top-margin bottom-margin">
          Kostenlose Demo
        </a>
      </section>
    </div>
  );
}
